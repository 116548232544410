.style-one.footer-area {
    background: url(../img/footer-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 60px 0 20px;
}

@media (min-width: 1300px) and (max-width: 1399px) {
    .style-one .footer-widget-desc p {
        width: 80%;
    }
}

.style-one .footer-widget-desc p {
    color: #fff;
    /* width: 70%; */
    margin: 20px 0 0;
    text-align: justify;
}

.inner-style.toptech-button a {
    display: inline-block;
    background: #0c5adb;
    border: 1px solid #0c5adb;
    color: #fff;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    margin-top: 20px;
    position: relative;
    transition: 0.5s;
    z-index: 1;
}

.inner-style.toptech-button a:before {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    right: 0;
    top: 0;
    background: #fff;
    z-index: -1;
    transition: 0.5s;
    border-radius: 5px;
}

.inner-style.toptech-button i {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    position: relative;
    top: 4px;
    left: 3px;
    transition: 0.5s;
}

.bi-arrow-right-short::before {
    content: "\f135";
}

.inner-style.toptech-button i {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    position: relative;
    top: 4px;
    left: 3px;
    transition: 0.5s;
}

.style-one .footer-widget-title h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}

.style-one .footer-widget-menu ul li a {
    display: inline-block;
    color: #fff;
    line-height: 35px;
    font-family: "Nunito";
    font-size: 14px;
    transition: 0.5s;
}

.style-one .footer-widget-menu ul li a:hover {
    color: #0c5adb;
    text-decoration: none;
}

.style-one .footer-widget-content ul li i {
    display: inline-block;
    color: #0D58D5;
    font-size: 16px;
    margin-right: 10px;
}

.bi-arrow-right-circle::before {
    content: "\f134";
}

.style-one .footer-widget-title h4 {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    margin: 20px 0px 12px;
}

.style-one p.f-desc-2 {
    color: #fff;
    margin: 0 0 27px;
    opacity: 0.8;
}

.style-one .footer-newslatter-box input {
    background: transparent;
    border: 1px solid #ffffff52;
    padding: 0 15px;
    height: 50px;
    width: 100%;
    color: #fff;
    border-radius: 5px;
    outline: 0;
}

.style-one .footer-newslatter-box button {
    background: #ee3234;
    color: #fff;
    border: transparent;
    outline: 0;
    padding: 10px 25px;
    border-radius: 20px;
    margin-top: 15px;
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;

}

.style-one .footer-newslatter-box button:hover {
    background: #fff;
    color: #000;
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.3);
}

.style-one .footer-bottom-area {
    border-top: 1px solid #ffffff21;
    margin-top: 50px;
    padding-top: 15px;
}

.style-one .footer-bottom-content p {
    color: #fff;
    margin: 0;
}

.footer-bottom-content p {
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 400;
    margin: 0;
}

.style-one .footer-botton-social-icon {
    text-align: right;
}

.footer-botton-social-icon ul li a {
    color: #fff;
    transition: color 0.3s ease;
}

.footer-botton-social-icon ul li a:hover {
    color: #0c5adb;
}

.style-one .footer-botton-social-icon ul li {
    display: inline-block;
    margin-right: 10px;
}

.style-one .footer-botton-social-icon ul li i {
    background: #354662;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
    color: #fff;
    font-size: 12px;
    margin-left: 5px;
    transition: 0.5s;
}

.style-one .footer-botton-social-icon ul li {
    display: inline-block;
}

.footer-widget-menu ul {
    list-style-type: none;
    padding-left: 0;
}

.footer-widget-menu ul li {
    margin-bottom: 8px;
}

.footer-widget-menu ul li a {
    text-decoration: none;
    color: inherit;
}

.footer-widget-menu ul li a:hover {
    text-decoration: none;
}

.whatsapp-float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    border-radius: 50%;
    width: 60px;
    /* Set a fixed width */
    height: 60px;
    /* Set a fixed height */
    padding: 0;
    /* Remove padding, it could distort the circle */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    transition: background-color 0.3s ease;
}


.whatsapp-float:hover {
    background-color: #128c7e;
}

.whatsapp-float .fa-whatsapp {
    color: white;
}
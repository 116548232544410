body {
    font-family: 'Roboto Slab', serif !important;
}


.hero1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    font-family: 'Arial', sans-serif;

}

.main-headding {
    font-size: 2.2rem;
    line-height: 1.6;
    color: #fff;
    text-align: left;
    margin-bottom: 20px;
}

.main-headding .span {
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
}

.hero1 .title {
    font-size: 3.2rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 25px;
}

.hero1 p {
    font-size: 17px;
    line-height: 1.8;
    color: #fff;
    max-width: 700px;
}

.buttons .theme-btn1,
.buttons .play-btn {
    font-size: 20px;
    font-weight: bold;
    padding: 8px 25px;
    margin-top: 25px;
    display: inline-block;
    text-decoration: none;
    border-radius: 30px;
    transition: all 0.3s ease;
}

.buttons .theme-btn1 {
    background-color: #1a2a5c;
    color: #fff;
}

.buttons .play-btn {
    background-color: transparent;
    color: #1a2a5c;
    border: 2px solid #1a2a5c;
    margin-left: 15px;
}

.buttons .theme-btn1:hover,
.buttons .play-btn:hover {
    background-color: #ffffff;
    color: #1a2a5c;
    border-color: #1a2a5c;
}

.hero1 .hero1-all-images img {
    max-width: 100%;
    height: auto;
}

/* Hide buttons on mobile screens */
/* @media (max-width: 768px) {
    .buttons .theme-btn1 {
        display: none;
    }
} */


.hero1 .hero1-all-images .image1,
.hero1 .hero1-all-images .image2,
.hero1 .hero1-all-images .image3,
.hero1 .hero1-all-images .image4 {
    margin-bottom: 30px;
}

.split-line div {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    animation: slideUp 0.5s forwards;
}

.split-line div:nth-child(1) {
    animation-delay: 0s;
}

.split-line div:nth-child(2) {
    animation-delay: 0.1s;
}

.split-line div:nth-child(3) {
    animation-delay: 0.2s;
}

.split-line div:nth-child(4) {
    animation-delay: 0.3s;
}

.split-line div:nth-child(5) {
    animation-delay: 0.4s;
}

.split-line div:nth-child(6) {
    animation-delay: 0.5s;
}

.split-line div:nth-child(7) {
    animation-delay: 0.6s;
}

.split-line div:nth-child(8) {
    animation-delay: 0.7s;
}

.split-line div:nth-child(9) {
    animation-delay: 0.8s;
}

.split-line div:nth-child(10) {
    animation-delay: 0.9s;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.split-line div:hover {
    transform: translateY(-10px);
}

@media screen and (max-width: 992px) {
    .hero1 {
        padding-top: 100px;
    }

    .main-headding {
        font-size: 2rem;
    }

    .hero1 .title {
        font-size: 2.5rem;
    }

    .hero1 p {
        font-size: 1.1rem;
    }

    .buttons .theme-btn1,
    .buttons .play-btn {
        font-size: 1rem;
        padding: 12px 30px;
    }

    .col-lg-5,
    .col-lg-7 {
        flex: 1 1 100%;
        margin-bottom: 30px;
    }

    .hero1 .hero1-all-images img {
        width: 100%;
    }
}



@media screen and (max-width: 375px) {
   

    .main-headding {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 320px) {

    .carousel-caption {
        bottom: 59px !important;
    }

}

@media screen and (max-width: 576px) {
    .hero1 {
        padding-top: 80px;
    }

    .main-headding {
        font-size: 1.6rem;
    }

    .hero1 .title {
        font-size: 2rem;
    }

    .hero1 p {
        font-size: 1rem;
        max-width: 100%;
    }

    .buttons .theme-btn1,
    .buttons .play-btn {
        font-size: 12px;
        padding: 10px 25px;
    }
    /* .carousel-caption {
        bottom: -29px !important;
    } */

    .hero1 .hero1-all-images img {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .serviceheading {
        
        font-size: 22px !important;
    }
}



.subcontent {
    font-size: 20px;
}

@media screen and (max-width: 320px) {
    .subcontent {
        font-size: 12px;
        margin-top: 12px;
    }
}
@media screen and (max-width: 425px) {
    .subcontent {
        font-size: 12px;
        margin-top: 12px;
    }
}

@media screen and (max-width: 375px) {
    .subcontent {
        font-size: 12px;
        margin-top: 12px;
    }
}

.navbar {
    background-color: #ffffff !important;
    padding: 15px 0;
}

.navbar-nav {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.navbar-nav .nav-link {
    color: #1a2a5c;
    font-size: 1rem;
    font-weight: 500;
    margin-left: 25px;
    margin-right: 25px;
    transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
    color: #1a2a5c;
    text-decoration: none;
}

.navbar-nav .nav-link.active {
    color: #1a2a5c !important;
    font-weight: 700;
}

.navbar-toggler-icon {
    background-color: #1a2a5c;
}

@media screen and (max-width: 992px) {

    .navbar {
        padding: 10px 0;
    }

    .navbar-nav {
        text-align: center;
        width: 100%;
    }

    .navbar-nav .nav-link {
        margin: 0 15px;
    }
}

.subtext {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
}

@media screen and (max-width: 320px) {
    .subtext {

        font-size: 14px;
    }
}
@media screen and (max-width: 375px) {
    .subtext {

        font-size: 14px;
    }
}


.caringhead {
    font-size: 40px;
}

@media screen and (max-width: 320px) {
    .caringhead {
        font-size: 14px;
    }
}


@media screen and (max-width: 375px) {
    .caringhead {
        font-size: 14px;
    }
}
/* abt */
.aboutsecnrispan {
    color: #1a2a5c !important;
    font-weight: 700 !important;
    font-size: 40px !important;

}

@media screen and (max-width: 320px) {
    .aboutsecnrispan {
        color: #1a2a5c !important;
        font-weight: 700 !important;
        font-size: 25px !important;

    }
}

.whowearecontent {
    text-align: justify;
}

/* djfhd */

.carousel-container {
    position: relative;
}

.navbar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: transparent;
    color: white;
}

.nrimenus {
    color: white;
    font-size: 17px;
    font-weight: 600;
}

.nrimenus.active {
    color: #f70000 !important;
}

.nrimenus:hover {
    color: #f70000;
}


.navbar .dropdown:hover .dropdown-menu {
    display: block !important;
    margin-top: 0;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.navbar .dropdown-menu {
    display: none;
    opacity: 0;
}

.navbar .dropdown-menu {
    transition: opacity 0.3s ease;
}

.banner-container {
    position: relative;
    width: 100%;
}

.banner-image {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.banner-image img {
    height: 100%;
    object-fit: cover;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    text-align: center;
}

.bannercontent {
    font-size: 90px;
}

.animated {
    font-size: 40px;
    font-weight: 500;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.helpbutton {
    font-size: 15px;
    padding: 15px 30px;
    background-color: #ee3234;
    color: white;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.helpbutton:hover {
    background-color: #fff;
    transform: scale(1.1);
    color: #000;
}

.helpbutton:focus {
    outline: none;
}

@media (max-width: 992px) {
    .nrimenus {
        font-size: 15px;
    }

    .bannercontent {
        font-size: 50px;
    }

    .animated {
        font-size: 30px;
    }

    .need-help-btn {
        font-size: 14px;
        padding: 12px 25px;
    }

    .centered-text {
        top: 40%;
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .navbar .navbar-nav {
        text-align: center;
    }

    .navbar-toggler {
        border-color: #fff;
    }

    .navbar-collapse {
        padding-top: 20px;
    }

    .bannercontent {
        font-size: 40px;
    }

    .animated {
        font-size: 25px;
    }

    .need-help-btn {
        font-size: 14px;
        padding: 10px 20px;
    }

    .centered-text {
        top: 35%;
    }
}

@media (max-width: 576px) {
    .bannercontent {
        font-size: 30px;
    }

    .animated {
        font-size: 20px;
    }

    .need-help-btn {
        font-size: 13px;
        padding: 8px 18px;
    }

    .centered-text {
        top: 30%;
    }
}

/* service */
.servicebackground {
    background-color: aliceblue;
}

.sec-title {
    margin: 0;
    color: #001328;
    font-weight: 800;
    font-size: 40px;
    letter-spacing: -0.03em;
}

.hover-effect:hover {
    background-color: red;
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
}

.hover-effect:hover div {
    background-color: white;
}


.single-solution-style-one {
    border: 1px solid #CCD7E0;
    padding: 36px 30px;
    border-radius: 6px;
    position: relative;
    margin-top: 70px;
    background: #fff;
}

.single-solution-style-one:before {
    background: #0f6fd5;
    border-radius: 6px;
}

.single-solution-style-one:hover {
    background-color: #0f6fd5;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(15, 111, 213, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(15, 111, 213, 0.3);
}

.single-solution-style-one:hover h3 a {
    color: #FFFFFF;
}

.single-solution-style-one:hover .read-more {
    border-color: #97B9EB;
    color: #E8F3FF;
}

.single-solution-style-one:hover span {
    color: #E8F3FF;
}

.single-solution-style-one .img-box {
    position: absolute;
    top: -40px;
    left: 30px;
}

.single-solution-style-one .img-box img {
    border-radius: 50%;
}

.single-solution-style-one .text-box {
    padding-left: 250px;
}

.single-solution-style-one h3 {
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}

.single-solution-style-one h3 a {
    color: #001328;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.single-solution-style-one span {
    color: #0f6fd5;
    font-size: 15px;
    font-weight: 700;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.single-solution-style-one .read-more {
    color: #0f6fd5;
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    line-height: 1em;
    border-bottom: 1px solid #A5BFEB;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.single-solution-style-one:hover span {
    color: #E8F3FF;
}

.single-solution-style-one:hover h3 a {
    color: #FFFFFF;
}

.single-solution-style-one:hover .read-more {
    border-color: #97B9EB;
    color: #E8F3FF;
}

@media (max-width: 1199px) {
    .single-solution-style-one {
        margin-top: 50px;
    }
}

@media (max-width: 991px) {
    .single-solution-style-one .img-box {
        position: relative;
        margin-bottom: 20px;
        text-align: center;
    }

    .single-solution-style-one .text-box {
        padding-left: 0;
        text-align: center;
    }

    .single-solution-style-one h3 {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .sec-title h2 {
        font-size: 20px;
    }

    .single-solution-style-one {
        padding: 20px;
        margin-top: 30px;
    }

    .single-solution-style-one .img-box {
        margin: 0 auto;
        display: block;
    }

    .single-solution-style-one .text-box {
        padding-left: 0;
    }

    .single-solution-style-one h3 {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .sec-title h2 {
        font-size: 18px;
    }

    .single-solution-style-one h3 {
        font-size: 14px;
    }

    .single-solution-style-one .text-box {
        padding-left: 0;
    }

    .single-solution-style-one .read-more {
        font-size: 10px;
    }
}


@media (max-width: 991px) {
    .solution-size-tab-wrapper .img-box {
        margin-bottom: 50px;
    }
}

.solution-size-tab-wrapper .text-box ul.list-items {
    margin-bottom: 30px;
    margin-top: 20px;
}

.easy-steps-style-one {
    background: #0f6fd5 url(../img/easy-step-bg.jpg) center center no-repeat;
    background-size: cover;
}

.single-easy-step-one {
    text-align: center;
}

.single-easy-step-one i {
    font-size: 70px;
    color: #fff;
}

.single-easy-step-one h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 800;
    margin: 0;
    margin-top: 25px;
}

.single-easy-step-one h3 a {
    color: #fff;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.single-easy-step-one h3 a:hover {
    color: #ee3234;
}

.single-easy-step-one p {
    color: #E8F3FF;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    width: 89%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.single-easy-step-one .read-more {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 65px;
    height: 40px;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 15px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.single-easy-step-one .read-more i {
    color: #fff;
    font-size: 12px;
    line-height: 40px;
}



.single-easy-step-one .read-more:hover i {
    color: #001328;
}

.single-easy-step-one .step-count {
    font-family: 'Covered By Your Grace';
    color: #FFFFFF;
    font-size: 18px;
}

.client-style-one {
    background: #f1f7fc;
    padding: 89px 0;
    text-align: center;
}

.client-carousel-one i {
    font-size: 126px;
    color: #0f6fd5;
    opacity: .3;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.client-carousel-one img {
    opacity: .3;
    transition: all .4s ease;
}

.client-carousel-one i:hover {
    opacity: 1;
}

.client-carousel-one img:hover {
    opacity: 1;
}

.client-carousel-one .owl-nav {
    display: none;
}

.client-style-one.home-two {
    background: #fff;
    border-bottom: 2px solid #f1f7fc;
}

.client-style-one.home-two .client-carousel-one i {
    color: #001328;
}

.client-style-one.home-two .client-carousel-one img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .5;
    transition: all .4s ease;
}

.client-style-one.home-two .client-carousel-one img:hover {
    opacity: 1;
}

.client-carousel-two i:hover {
    opacity: 1;
}

.client-carousel-two img:hover {
    opacity: 1;
}

.client-carousel-two .owl-nav {
    margin: 0;
}

.client-carousel-two .item {
    border-radius: 7px;
    text-align: center;
    border: 1px solid #ccd7e0;
    padding: 36.5px 0;
}

.client-carousel-two i {
    font-size: 150px;
    color: #001328;
    opacity: .3;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.client-carousel-two i:hover {
    opacity: 1;
}

.client-carousel-two img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .5;
    transition: all .4s ease;
}

.client-carousel-two img:hover {
    opacity: 1;
}

.client-carousel-two i:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .sec-title h2 {
        font-size: 1.8rem;
    }

    .single-easy-step-one p {
        font-size: 0.9rem;
    }

    .single-easy-step-one {
        padding: 15px;
    }
}

.sec-pad {
    padding: 120px 0;
}

.sec-pad-top {
    padding-top: 120px;
}

.sec-pad-bottom {
    padding-bottom: 120px;
}

.sec-title {
    margin-bottom: 60px;
}

.sec-title span {
    display: block;
    text-transform: uppercase;
    color: #0057B3;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .04em;
    margin-bottom: 5px;
}

.sec-title span:before {
    content: '';
    width: 16px;
    height: 8px;
    background: #0057b3;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: block;
    margin-bottom: 22px;
}

.sec-title.text-center span:before {
    margin-left: auto;
    margin-right: auto;
}

.sec-title h2 {
    margin: 0;
    color: #001328;
    font-weight: 800;
    font-size: 40px;
    letter-spacing: -0.03em;
}

.sec-title.light span,
.sec-title.light h2 {
    color: #FFFFFF;
}

.sec-title.light span:before {
    background: #FFFFFF;
}

.thm-btn {
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    color: #FFFFFF;
    background: #ee3234;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: .04em;
    text-transform: uppercase;
    border-radius: 30px;
    padding: 18px 39.5px;
    text-align: center;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(238, 50, 52, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(238, 50, 52, 0.3);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.thm-btn:hover {
    background: #001328;
    color: #FFFFFF;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 19, 40, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(0, 19, 40, 0.3);
}

.bootstrap-select .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    z-index: 991;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
    background-color: transparent;
}

.bootstrap-select .dropdown-menu>li+li>a {
    border-top: 1px solid #eee;
}

.bootstrap-select .dropdown-menu>li:first-child>a {
    padding-top: 10px;
}

.bootstrap-select .dropdown-menu>li:last-child>a {
    padding-bottom: 10px;
}

.bootstrap-select .dropdown-menu>li.selected>a {
    background: #ee3234;
    color: #fff;
}

.bootstrap-select .dropdown-menu>li>a {
    font-size: 16px;
    font-weight: 600;
    padding: 9px 20px;
    color: #000;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
    background: #ee3234;
    color: #fff;
    cursor: pointer;
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
}

.large-paragraph {
    color: #798593;
    font-size: 18px;
    line-height: 34px;
    font-weight: 600;
}

.list-items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list-items li {
    position: relative;
    font-size: 18px;
    line-height: 34px;
    padding-left: 30px;
    color: #798593;
    font-weight: 600;
}

.list-items li i {
    color: #ee3234;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 0;
}

.list-items li.disabled {
    color: #798593;
}

.list-items li.disabled i {
    color: #798593;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.scroll-to-top {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ee3234;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    text-align: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    display: none;
}

.scroll-to-top i {
    font-size: 18px;
    line-height: 50px;
    color: #fff;
}

.scroll-to-top:hover {
    background: #001328;
}

a[href^="http://maps.google.com/maps"] {
    display: none !important;
}

a[href^="https://maps.google.com/maps"] {
    display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
    display: none;
}

.gmnoprint div {
    background: none !important;
}

.top-bar {
    background: #0f6fd5;
    padding: 14.75px 50px;
}

.top-bar .left-content p {
    margin: 0;
    color: #E7F2FF;
    font-size: 13px;
    font-weight: 600;
}

.top-bar .left-content p i {
    margin-right: 10px;
}

.top-bar .left-content p a {
    color: #FFFFFF;
    font-weight: 800;
    text-decoration: underline;
}

.top-bar .right-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.top-bar .right-content ul li {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
}

.top-bar .right-content ul li+li {
    margin-left: 16px;
}

.top-bar .right-content ul li span {
    font-weight: 800;
    margin-right: 16px;
    margin-left: 3px;
}

.top-bar .right-content ul li a {
    color: #E8F3FF;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.top-bar .right-content ul li a:hover {
    color: #ee3234;
}

.top-bar .right-content ul li i {
    font-size: 14px;
}

.hidden-sidebar {
    position: fixed;
    top: 0;
    right: -530px;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    max-width: 530px;
    background: #FFFFFF;
    overflow: auto;
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.hidden-sidebar.open {
    -webkit-transform: translate3d(-530px, 0, 0);
    transform: translate3d(-530px, 0, 0);
}

.hidden-sidebar .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    color: #798593;
    font-size: 14px;
    cursor: pointer;
}

.hidden-sidebar .sidebar-content {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 45px;
    text-align: center;
}

.hidden-sidebar .sidebar-content img {
    border-radius: 6px;
}

.hidden-sidebar .sidebar-content h3,
.hidden-sidebar .sidebar-content p {
    margin: 0;
}

.hidden-sidebar .sidebar-content p {
    color: #798593;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.hidden-sidebar .sidebar-content p.contact-info {
    color: #001328;
    font-size: 22px;
    font-weight: 800;
    line-height: 1em;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 70px;
}

.hidden-sidebar .sidebar-content p.contact-info i {
    display: inline-block;
    vertical-align: middle;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    background: #0f6fd5;
    line-height: 63px;
    color: #fff;
    font-size: 26px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(15, 111, 213, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(15, 111, 213, 0.3);
    margin-right: 20px;
}

.hidden-sidebar .sidebar-content a.download-btn {
    background: #ee3234;
    width: 100%;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    display: block;
    position: relative;
    padding: 38px 0;
    padding-left: 85px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(238, 50, 52, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(238, 50, 52, 0.3);
    text-align: left;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.hidden-sidebar .sidebar-content a.download-btn.two {
    background: #0f6fd5;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(15, 111, 213, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(15, 111, 213, 0.3);
    margin-top: 30px;
}

.hidden-sidebar .sidebar-content a.download-btn i {
    font-size: 29px;
    position: absolute;
    top: 50%;
    left: 40px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.hidden-sidebar .sidebar-content a.download-btn:hover {
    background: #fff;
    color: #001328;
}


.search_area {
    position: relative;
    max-width: 950px;
    margin: auto;
}

.search_area .search_box_inner {
    background: transparent;
    padding: 100px 60px;
    display: block;
    overflow: hidden;
}

.search_area .search_box_inner h3 {
    padding-bottom: 20px;
    font-family: "Hind", sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    color: #fff;
}

.search_area .search_box_inner .input-box {
    display: block;
    position: relative;
}

.search_area .search_box_inner .input-box input {
    background: transparent;
    border-radius: 0px;
    outline: none;
    height: 50px;
    padding: 0px;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    font-family: "Hind", sans-serif;
    font-size: 24px;
    font-weight: normal;
}

.search_area .search_box_inner .input-box input::-webkit-input-placeholder {
    color: #fff;
}

.search_area .search_box_inner .input-box input::-moz-placeholder {
    color: #fff;
}

.search_area .search_box_inner .input-box input:-ms-input-placeholder {
    color: #fff;
}

.search_area .search_box_inner .input-box input:-moz-placeholder {
    color: #fff;
}

.search_area .search_box_inner .input-box .input-box-btn {
    position: absolute;
    right: 5px;
    top: 14px;
    z-index: 4;
}

.search_area .search_box_inner .input-box .input-box-btn .btn-default {
    padding: 0px;
    border: none;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent;
    color: #fff;
    font-size: 18px;
}

.search_area .mfp-close {
    right: 0px;
    color: #fff;
}

.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
}

.my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.98;
}


.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}


.my-mfp-slide-bottom .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
    transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: translateY(0) perspective(600px) rotateX(0);
    transform: translateY(0) perspective(600px) rotateX(0);
}

.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
    opacity: 0;
    -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
    transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

.my-mfp-slide-bottom.mfp-bg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

.my-mfp-slide-bottom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.icon-large-white {
    font-size: 48px;
    color: white;
}

.rightarrow {
    color: #0f6fd5;
    margin-top: 12px;
}

/* form */
.subscribe-form {
    background: #0f6fd5 url(../img/subscribe-bg-1-1.jpg) center center no-repeat;
    background-size: cover;
    border-radius: 6px;
    padding: 58px;
    padding-top: 54px;
    padding-bottom: 60px;
    margin-top: 30px;
}

.expertize-box-one {
    background: #ee3234;
    text-align: center;
    border-radius: 6px;
    padding: 56px 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 300px;
}

.sec-pad {
    padding: 120px 0;
}

.video-box-style-one {
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 6px;
}

.video-box-style-one .img-box img {
    width: 100%;
}

.video-box-style-one .box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(0, 19, 40, 0.5);
}

.video-box-style-one .box a {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
}

a {
    text-decoration: none;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.video-box-style-one .box a .btn-tag-line {
    position: absolute;
    bottom: -50px;
    left: 50%;
    width: 100%;
    -webkit-transform: translateX(-65%);
    transform: translateX(-65%);
    color: #FFFFFF;
    font-family: 'Covered By Your Grace';
    font-size: 20px;
}

.video-box-style-one .box a .btn-tag-line i {
    display: inline-block;
    -webkit-transform: rotate(-85deg) scale(1, -1) translate(3px, 0px);
    transform: rotate(-85deg) scale(1, -1) translate(3px, 0px);
}

payonline-icon-share:before {
    content: "\e909";
}

.video-box-style-one .box a .btn-tag-line {
    position: absolute;
    bottom: -50px;
    left: 50%;
    width: 100%;
    -webkit-transform: translateX(-65%);
    transform: translateX(-65%);
    color: #FFFFFF;
    font-family: 'Covered By Your Grace';
    font-size: 20px;
}

.video-box-style-one .box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(0, 19, 40, 0.5);
}

.expertize-box-one .inner-box {
    width: 100%;
}

.expertize-box-one h3 {
    color: #FFFFFF;
    font-size: 40px;
    line-height: 36px;
    font-weight: 800;
}

.expertize-box-one h3,
.expertize-box-one p {
    margin: 0;
}

.expertize-box-one p {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    margin-top: 13px;
    margin-bottom: 50px;
}

.expertize-box-one h3,
.expertize-box-one p {
    margin: 0;
}

.expertize-box-one a {
    font-weight: 800;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-size: 12px;
    line-height: 1em;
    text-decoration: underline;
}

.expertize-box-one .inner-box {
    width: 100%;
}

.subscribe-form h3 {
    margin: 0;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 48px;
}

.subscribe-form input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    max-width: 290px;
    background: #FFFFFF;
    height: 60px;
    border-radius: 30px;
    color: #798593;
    font-size: 16px;
    font-weight: 600;
    padding-left: 40px;
    margin-right: 10px;
}

.thm-btn {
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    color: #FFFFFF;
    background: #ee3234;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: .04em;
    text-transform: uppercase;
    border-radius: 30px;
    padding: 18px 39.5px;
    text-align: center;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(238, 50, 52, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(238, 50, 52, 0.3);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.video-box-style-one .box a {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
}

.get-apps-style-one {
    background: #f1f7fc url(../img/gray-shape-bg.jpg) center center no-repeat;
    background-size: cover;
    padding-top: 130px;
    /* padding-bottom: 80px; */
}

.get-apps-style-one-content {
    padding-right: 59px;
}

.get-apps-style-one-content .sec-title {
    margin-bottom: 10px;
}

.sec-title span {
    display: block;
    text-transform: uppercase;
    color: #0057B3;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .04em;
    margin-bottom: 5px;
}

.sec-title span:before {
    content: '';
    width: 16px;
    height: 8px;
    background: #0057b3;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: block;
    margin-bottom: 22px;
}

.sec-title h2 {
    margin: 0;
    color: #001328;
    font-weight: 800;
    font-size: 40px;
    letter-spacing: -0.03em;
}

.get-apps-style-one-content .thm-btn.style-two {
    background: #0f6fd5;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(15, 111, 213, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(15, 111, 213, 0.3);
    margin-right: 10px;
}

.get-apps-style-one-content .thm-btn {
    position: relative;
}

.get-apps-style-one-content .thm-btn {
    position: relative;
}

a:hover,
a:focus,
a:visited {
    text-decoration: none;
}

.thm-btn {
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    color: #FFFFFF;
    background: #ee3234;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: .04em;
    text-transform: uppercase;
    border-radius: 30px;
    padding: 18px 39.5px;
    text-align: center;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(238, 50, 52, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(238, 50, 52, 0.3);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.get-apps-style-one-content .btn-tag-line {
    display: block;
    color: #798593;
    font-size: 20px;
    font-family: 'Covered By Your Grace';
    letter-spacing: 0;
    margin-left: 55px;
    margin-top: 20px;
}

.get-apps-style-one-content .btn-tag-line i {
    display: inline-block;
    -webkit-transform: rotate(-85deg) scale(1, -1) translate(3px, 0px);
    transform: rotate(-85deg) scale(1, -1) translate(3px, 0px);
    font-size: 27px;
}

.get-apps-style-one .featured-image {}

/* blog */
.sec-title {
    margin-bottom: 60px;
}

.sec-title.text-center span:before {
    margin-left: auto;
    margin-right: auto;
}

.sec-title span:before {
    content: '';
    width: 16px;
    height: 8px;
    background: #0057b3;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: block;
    margin-bottom: 22px;
}

.sec-title h2 {
    margin: 0;
    color: #001328;
    font-weight: 800;
    font-size: 40px;
    letter-spacing: -0.03em;
}

.sec-title {
    margin-bottom: 60px;
}

.single-blog-style-one .text-box {
    background: #f1f7fc;
    border-radius: 6px;
    text-align: center;
    padding: 36.5px 55px;
    margin-bottom: 20px;
    overflow: hidden;
}

.hvr-sweep-to-bottom {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.single-blog-style-one .image-box {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
}

.single-blog-style-one .image-box img {
    width: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.single-blog-style-one .box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(0, 19, 40, 0.6);
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transition: all 0.6s ease-in-out 0.3s;
    transition: all 0.6s ease-in-out 0.3s;
}

.single-blog-style-one .box a {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
}

.single-blog-style-one .box a i.fa {
    display: inline-block;
    width: 65px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 20px;
    color: #001328;
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.fa-arrow-right:before {
    content: "\f061";
}

.single-blog-style-one .box a i.fa:hover {
    color: #fff;
    background: #1371D6;
}

.single-blog-style-one .box a i.fa {
    display: inline-block;
    width: 65px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 20px;
    color: #001328;
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

@media (max-width: 1200px) {
    .col-lg-4 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media (max-width: 992px) {
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 768px) {
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.single-blog-style-one .text-box {
    transition: all 0.3s ease-in-out;
}

.single-blog-style-one .text-box:hover {
    transform: translateY(-10px);
}

.read-more {
    font-weight: bold;
    color: #007bff;
}

.read-more:hover {
    color: #0056b3;
}

.cta-one {
    background: #f1f7fc url(../img/gray-shape-bg2.jpg) center center no-repeat;
    background-size: cover;
}

.sec-pad {
    padding: 120px 0;
}

.cta-one-content .sec-title {
    margin-bottom: 50px;
}

.sec-title span {
    display: block;
    text-transform: uppercase;
    color: #0057B3;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .04em;
    margin-bottom: 5px;
}

cta-one-content .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
    max-width: 470px;
    border: 2px solid #CCD7E0;
    border-radius: 30px;
    margin-bottom: 20px;
}

.cta-one-content .bootstrap-select>.dropdown-toggle {
    border: none !important;
    outline: none !important;
    background-color: transparent;
    padding: 18px 38px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.cta-one-content .dropdown-toggle::after {
    border: none;
    width: auto;
    height: auto;
    content: '\f107';
    font-family: 'FontAwesome';
    color: #798593;
    font-size: 16px;
    width: 66px;
    height: 60px;
    text-align: center;
    border-left: 2px solid #CCD7E0;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 60px;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.site-footer {
    background: #001328 url(../img/footer-bg-1-1.jpg) center right no-repeat;
    background-size: cover;
}

.site-footer .upper-footer {
    padding-top: 100px;
    padding-bottom: 100px;
}

.footer-widget.links-widget-one {
    padding-left: 20px;
}

.footer-widget .title-box h3 {
    margin: 0;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px;
}

.footer-widget ul.link-lists {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-widget ul.link-lists li a {
    color: #DBE9FA;
    font-size: 16px;
    font-weight: 700;
    display: block;
    position: relative;
    padding-right: 20px;
}

.footer-widget ul.link-lists li a:after {
    content: '\f061';
    font-family: 'FontAwesome';
    font-size: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%) translateX(10px);
    transform: translateY(-50%) translateX(10px);
    opacity: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}


.footer-widget ul.link-lists li+li {
    margin-top: 10px;
}

.footer-widget ul.link-lists li a {
    color: #DBE9FA;
    font-size: 16px;
    font-weight: 700;
    display: block;
    position: relative;
    padding-right: 20px;
}

.footer-widget .btn-box,
.footer-widget .social {
    display: inline-block;
    vertical-align: top;
}

.footer-widget .btn-box .thm-btn {
    padding: 18px 62.5px;
}

.footer-widget .btn-box .btn-tag-line {
    display: block;
    color: #DBEAFB;
    font-size: 20px;
    font-family: 'Covered By Your Grace';
    letter-spacing: 0;
    margin-top: 20px;
}

.footer-widget .btn-box .btn-tag-line i {
    display: inline-block;
    -webkit-transform: rotate(-85deg) scale(1, -1) translate(3px, 0px);
    transform: rotate(-85deg) scale(1, -1) translate(3px, 0px);
    font-size: 27px;
}

.payonline-icon-share:before {
    content: "\e909";
}

.footer-widget .social {
    padding: 16.5px 0;
    margin-left: 40px;
}

footer-widget .social a {
    color: #FFFFFF;
    font-size: 22px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.blogtext {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.logo img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .logo img {
        max-width: 80%;
    }
}

@media (max-width: 480px) {
    .logo img {
        max-width: 70%;
    }
}

.homenricontent {
    font-size: 70px;
    color: #fff;
}

.homecontentnri {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
}


@media only screen and (max-width: 320px) {
    .homenricontent {
        font-size: 30px;
    }

    .homecontentnri {
        font-size: 16px;
    }

    .sec-title h2 {
        font-size: 16px;
    }
}

.nrihomebanner {
    background: url('../img/banner-1-3.jpg') no-repeat center center/cover;
    padding: 211px 0 200px;
    background-size: cover;
    position: relative;
    margin-top: 0;
    height: 100vh;
}

/* faq */
.default-padding {
    padding-top: 120px;
    padding-bottom: 120px;
}

.default-padding,
.default-padding-top,
.default-padding-bottom,
.default-padding-mx {
    position: relative;
    z-index: 1;
}

.bg-gray {
    background-color: #f3f7fd;
}

* {
    padding: 0;
    margin: 0;
}

.faq-area .info>h5 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 25px;
    display: inline-block;
    position: relative;
    z-index: 1;
    padding-left: 45px;
    color: #086AD8;
}

.faq-area .info>h5::after {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    height: 2px;
    width: 30px;
    background: #086AD8;
    margin-top: -1px;
}

.faq-area .info h2 {
    font-weight: 600;
    margin-bottom: 25px;
}

.btn-theme {
    background-color: #086AD8;
    color: #ffffff !important;
    border: 2px solid #086AD8;
}

.btn-md {
    padding: 15px 40px;
}

.btn {
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 25px;
    text-transform: capitalize;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    overflow: hidden;
    border-radius: 5px;
    font-size: 17px;
}

.faq-area .faq-content {
    margin-top: 0;
}

.faq-area .faq-content {
    margin-top: 25px;
}

.faq-area .faq-content .card {
    border: none;
    margin-bottom: 31px;
    overflow: inherit;
    background: transparent;
    border-radius: 5px;
    padding: 0;
}

.faq-area .faq-content .card .card-header {
    border: none;
    background: transparent;
    padding: 0;
}

.faq-area .faq-content .card .card-header h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
    padding-right: 50px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
}

.faq-area .faq-content .card .card-header h4 strong {
    display: inline-block;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background: #086AD8;
    color: #ffffff;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
    min-width: 40px;
}

.faq-area .faq-content .card .card-header h4 strong::after {
    position: absolute;
    left: -10%;
    top: -10%;
    content: "";
    height: 120%;
    width: 120%;
    background: #086AD8;
    z-index: -1;
    border-radius: 50%;
    opacity: 0.3;
}

.accordion .card-header h4:after {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 600;
}

.faq-area .faq-content .card .card-body {
    padding-left: 30px;
    padding: 0;
    padding-top: 15px;
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
}

.faq-area .faq-content .card .card-body {
    padding-left: 30px;
    padding: 0;
    padding-top: 15px;
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
}

.faq-area .faq-content .card .card-body p:last-child {
    margin-bottom: 0;
}

.faq-area .faq-content .card .card-body p:last-child {
    margin-bottom: 0;

}

.faq-area .faq-content .card .card-body {
    padding-left: 30px;
    padding: 0;
    padding-top: 15px;
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
}

.faq-area .faq-content .card {
    border: none;
    margin-bottom: 31px;
    overflow: inherit;
    background: transparent;
    border-radius: 5px;
    padding: 0;
}

.faq-area .faq-content .card {
    border: none;
    margin-bottom: 31px;
    overflow: inherit;
    background: transparent;
    border-radius: 5px;
    padding: 0;
}

.faq-area .faq-content .card .card-header {
    border: none;
    background: transparent;
    padding: 0;
}

.faq-area .faq-content .card .card-header h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
    padding-right: 50px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
}

.faq-area .faq-content .card:last-child {
    margin-bottom: 0;
}

.faq-area .faq-content .card {
    border: none;
    margin-bottom: 31px;
    overflow: inherit;
    background: transparent;
    border-radius: 5px;
    padding: 0;
}

.faq-area .faq-content .card .card-body {
    padding-left: 30px;
    padding: 0;
    padding-top: 15px;
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
}

.collapse-custom {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.collapse-custom.open {
    max-height: 500px;
}

.rotate-180 {
    transform: rotate(180deg);
}

.bannernricontent {
    color: white;
    text-align: justify;
    font-size: 19px;
}

.bannernricontent span {
    display: block;
}

.style-one.work-process-area {
    padding: 108px 0 89px;
}

.style-one.work-process-area .section-title.center {
    margin-bottom: 58px;
}

.style-one .single-process-box {
    text-align: center;
    padding: 0 9px 0;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
}

.style-one .single-process-box:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: -64px;
    top: 47px;
    background: url(../img/process-shape.png);
    background-repeat: no-repeat;
    z-index: -1;
}

.single-process-box.before-transprant:before {
    background: transparent !important;
}

.style-one .single-process-box:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: -64px;
    top: 47px;
    background: url(../img/process-shape2.png);
    background-repeat: no-repeat;
    z-index: -1;
}

@media (max-width: 768px) {

    .style-one .single-process-box:before,
    .style-one .single-process-box:after {
        display: none !important;
    }
}


.single-process-box.after-transprant:after {
    background: transparent;
}

.style-one .process-box-thumb {
    width: 145px;
    height: 145px;
    line-height: 138px;
    border-radius: 150px;
    display: inline-block;
    color: #086AD8;
    font-size: 65px;
    border: 2px solid #ededed;
    transition: all 500ms ease;
    text-align: center;
    position: relative;
}

.style-one .single-process-box:hover .process-box-thumb {
    border: 2px dashed #086AD8;
}

.style-one .process-box-thumb img {
    transition: 0.5s;
}

.style-one .single-process-box:hover img {
    transform: scaleX(-1);
}

.style-one .process-content h4 {
    font-size: 23px;
    margin: 30px 0 10px;
}

.style-one .process-number span {
    position: absolute;
    right: 0%;
    top: 0px;
    z-index: 1;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 50px;
    display: inline-block;
    font-size: 18px;
    color: #fff;
    background-color: #8abf4f;
    transition: .5s;
}

.works-area.style-foure {
    background: #F3F8FC;
    padding: 120px 0 120px;
}

.works-area.style-foure .works-thumb {
    position: relative;
    z-index: 1;
}

.works-area.style-foure .works-video-icon {
    position: absolute;
    top: 39%;
    left: 40%;
}

.works-area.style-foure .works-video-icon a {
    display: inline-block;
    background: #0C59DB;
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    height: 100px;
    width: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}

.works-area.style-foure .works-video-icon a::before {
    position: absolute;
    content: "";
    height: 80px;
    width: 80px;
    border: 1px dashed #ffff;
    left: 10%;
    top: 11%;
    border-radius: 50%;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.works-area.style-foure .section-title.style-foure.text-left {
    margin-bottom: 0;
}

.section-title.style-foure h5.section-sub-title {
    font-size: 18px;
    text-transform: uppercase;
    color: #0c5adb;
    font-weight: 500;
    margin: 0;
    margin-bottom: 12px;
    padding-left: 29px;
    position: relative;
    z-index: 1;
    display: inline-block;
}

.section-title.style-foure h5.section-sub-title::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../img/title-shape2.png);
    background-repeat: no-repeat;
    left: 0;
    top: 4px;
}

.section-title.style-foure h1.section-main-title {
    font-size: 46px;

    line-height: 45px;
    color: #041424;
    font-weight: 700;
}

.section-title.style-foure p.description {
    font-size: 18px;
    line-height: 28px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
    padding: 19px 0 0;
    width: 85%;
}

.works-area.style-foure p.works {
    font-size: 18px;
    line-height: 26px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
    padding: 30px 0 0;
    width: 84%;
}

.dreamit-button {
    margin-top: 30px;
    display: inline-block;
}

.dreamit-button a {
    display: inline-block;
    background: #0c5adb;
    padding: 15px 35px;
    margin-right: 14px;
    position: relative;
    border-radius: 30px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Nunito";
    z-index: 1;
}

.dreamit-button a::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #00004A;
    border-radius: 30px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition: .5s;
    clip-path: polygon(50% 0, 50% 0, 50% 50%, 50% 100%, 50% 100%, 50% 50%);
    z-index: -1;
}

.dreamit-button {
    margin-top: 30px;
    display: inline-block;
}

.dreamit-button2 {
    display: inline-block;
}

.dreamit-button2 a {
    display: inline-block;
    font-size: 16px;
    line-height: 48px;
    color: #686868;
    font-weight: 500;
    font-family: "Nunito";
}

.dreamit-button2 a i {
    display: inline-block;
    font-size: 18px;
    margin-left: 4px;
}

.section-title {
    margin-bottom: 0 !important;
}

.work-card {
    border: 1px solid #0c5adb;
    border-image-slice: 1;
    border-radius: 17px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-left 0.3s ease;
    background-color: #fff;
    overflow: hidden;
}

.work-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
    border-left: 3px solid #0c5adb;
}

.work-card:hover {
    border-top: 3px solid #0c5adb;
    border-right: 3px solid #0c5adb;
    border-bottom: 3px solid #0c5adb;
}


.work-card-body {
    padding: 30px;
    text-align: center;
    position: relative;
}

.work-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.work-card-text {
    font-size: 1.1rem;
    color: #777;
    line-height: 1.6;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .work-card-body {
        padding: 20px;
    }

    .work-card-title {
        font-size: 1.25rem;
    }

    .work-card-text {
        font-size: 1rem;
    }
}

.works-area .section-title h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
}

.works-area .section-title h5 {
    font-size: 1.25rem;
    color: #777;
}



.icon-card {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border: 'none';
}

.icon-large {
    font-size: 2rem;
    color: #8abf4f;
}

.contact-text {
    font-size: 16px;
    color: #333;
    text-decoration: none;
}

.ms-3 {
    margin-left: 1rem;
}

.ctasection {
    color: #000;
    font-size: 32px !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .work-process-area .single-process-box {
        margin-bottom: 30px;
    }
}

@media (max-width: 480px) {
    .work-process-area .process-content h4 {
        font-size: 18px;
    }

    .work-process-area .process-content p {
        font-size: 14px;
    }
}

.servicelist {
    font-size: 14px;

}

.servicelist :hover {
    font-size: 14px;
    color: #000;
}

.rs-why-choose {
    position: relative;
    z-index: 10;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-why-choose .rs-section-title {
        text-align: left;
        margin-top: 45px;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose .rs-section-title {
        text-align: left;
        margin-top: 45px;
    }
}

.rs-why-choose__content {
    padding-left: 85px;
}



@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rs-why-choose__content {
        padding-left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-why-choose__content {
        padding-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose__content {
        padding-left: 0;
    }
}

.rs-why-choose__item-list ul li {
    display: flex;
    align-items: center;
    margin-top: 22px;
}

.rs-why-choose__item-list ul li .thumb {
    width: 60px;
    margin-right: 20px;
}

@media only screen and (max-width: 575px) {
    .rs-why-choose__item-list ul li .thumb {
        width: 100px;
    }
}

.rs-why-choose__item-list ul li .content .title {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 30px;
}

@media only screen and (max-width: 575px) {
    .rs-why-choose__item-list ul li .content .title {
        line-height: 24px;
    }
}

.rs-why-choose__shape-1 {
    position: absolute;
    left: 0;
    top: 170px;
    z-index: -1;
}

@media only screen and (max-width: 767px) {
    .rs-why-choose__shape-1 {
        display: none;
    }
}

.rs-why-choose__shape-2 {
    position: absolute;
    right: 0;
    top: 260px;
    z-index: -1;
}

@media only screen and (max-width: 767px) {
    .rs-why-choose__shape-2 {
        display: none;
    }
}

.rs-why-choose__thumb img.item-2 {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-why-choose.rs-why-choose-3 {
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose.rs-why-choose-3 {
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .rs-why-choose.rs-why-choose-3 {
        padding-top: 90px;
    }
}

.rs-why-choose.rs-why-choose-3 .section-title-2 {
    text-align: left;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb {
    padding-right: 90px;
    position: relative;
    z-index: 10;
}

@media only screen and (max-width: 1599px) {
    .rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb {
        padding-right: 0;
    }
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .shape-1 {
    position: absolute;
    top: -40px;
    right: 60px;
}

@media only screen and (max-width: 1599px) {
    .rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .shape-1 {
        top: -60px;
        right: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .shape-1 {
        position: static;
    }
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .shape-2 {
    position: absolute;
    left: 90px;
    top: -80px;
    z-index: -1;
    -webkit-animation: translateor3 4s linear infinite;
    animation: translateor3 4s linear infinite;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .shape-3 {
    position: absolute;
    left: -80px;
    bottom: -95px;
    z-index: -1;
    -webkit-animation: translateor3 4s linear infinite;
    animation: translateor3 4s linear infinite;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .rs-why-choose-3__counter {
    background: #002559;
    border: 3px solid #ffffff;
    border-radius: 6px;
    width: 280px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 11px 30px 20px;
}

@media only screen and (max-width: 575px) {
    .rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .rs-why-choose-3__counter {
        position: static;
        margin-top: 30px;
    }
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .rs-why-choose-3__counter .counter-item {
    display: flex;
    align-items: center;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .rs-why-choose-3__counter .counter-item .icon {
    width: 50px;
    margin-right: 15px;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .rs-why-choose-3__counter .counter-item .title {
    font-size: 50px;
    color: #ffffff;
    font-family: "Urbanist", sans-serif;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__thumb .rs-why-choose-3__counter p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__content {
    padding-left: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-why-choose.rs-why-choose-3 .rs-why-choose__content {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose.rs-why-choose-3 .rs-why-choose__content {
        margin-top: 50px;
    }
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__content .rs-why-choose__item-list {
    position: relative;
    z-index: 10;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__content .rs-why-choose__item-list ul li .content .title {
    color: #010047;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__content .rs-why-choose__item-list>p {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 700;
    color: #010047;
    font-family: "Urbanist", sans-serif;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__content .rs-why-choose__item-list>p a {
    font-family: "Urbanist", sans-serif;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: transparent;
    background-image: linear-gradient(99.43deg, #0c43fe 0.99%, #fc3737 99.43%);
    margin-left: 10px;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__content .rs-why-choose__item-list>p a i {
    font-size: 20px;
}

.rs-why-choose.rs-why-choose-3 .rs-why-choose__content .rs-why-choose__item-list .content-shape {
    position: absolute;
    left: -90px;
    bottom: -40px;
}

@media only screen and (max-width: 767px) {
    .rs-why-choose.rs-why-choose-4 {
        padding-top: 80px;
    }
}

.rs-why-choose.rs-why-choose-4 .rs-why-choose__shape-2 {
    top: 110px;
}

.rs-why-choose.rs-why-choose-4 .rs-why-choose__content {
    padding-left: 0;
    padding-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-why-choose.rs-why-choose-4 .rs-why-choose__content {
        margin-top: 40px;
        padding-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose.rs-why-choose-4 .rs-why-choose__content {
        margin-top: 40px;
        padding-right: 0;
    }
}

.rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb {
    margin-right: 45px;
    display: flex;
    position: relative;
    z-index: 10;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb {
        margin-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb {
        margin-left: 0;
    }
}

@media only screen and (max-width: 575px) {
    .rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb {
        display: block;
    }
}

.rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb .rs-thumb-1 {
    margin-right: 20px;
    min-width: 310px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb .rs-thumb-1 {
        min-width: 210px;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb .rs-thumb-1 {
        min-width: 285px;
    }
}

@media only screen and (max-width: 575px) {
    .rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb .rs-thumb-1 {
        margin-bottom: 30px;
        width: inherit;
        margin-right: 0;
    }
}

.rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb .rs-thumb-1 img {
    border-radius: 10px;
    width: 100%;
}

.rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb .rs-thumb-2 img {
    margin-bottom: 15px;
    border-radius: 10px;
    width: 100%;
}

.rs-why-choose.rs-why-choose-4 .rs-why-choose-4__thumb .rs-shape {
    position: absolute;
    left: -120px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.rs-why-choose.rs-why-choose-4.rs-about-why-choose .rs-why-choose__shape-2 {
    opacity: 0.5;
    top: 300px;
}

.rs-why-choose.rs-why-choose-4.rs-about-why-choose .rs-why-choose__content .section-title-2 .top-sub-heading span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: transparent;
    background-image: linear-gradient(99.43deg, #513DE8 0.99%, #8366E3 99.43%);
}

.rs-why-choose.service-why-choose {
    background: #F7F7F7;
    padding-bottom: 115px;
}

.rs-why-choose.service-why-choose .section-title-3 .top-sub-heading span {
    background: -webkit-linear-gradient(130deg, #f28e26 0%, #f24c1a 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rs-why-choose.service-why-choose .section-title-3 .title {
    color: #002559;
}

.rs-why-choose.service-why-choose .rs-why-choose__item-list ul li .content .title {
    color: #002559;
}

.rs-why-choose-2 .rs-section-title {
    padding-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rs-why-choose-2 .rs-section-title {
        padding-right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-why-choose-2 .rs-section-title {
        padding-right: 0;
        text-align: left;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose-2 .rs-section-title {
        padding-right: 0;
        text-align: left;
    }
}

.rs-why-choose-2 .rs-section-title .top-sub-heading span {
    background-color: transparent;
    background-image: linear-gradient(99.43deg, #f24c1a 0.99%, #f28e26 99.43%);
}

.rs-why-choose-2 .rs-section-title .title {
    color: #002559;
}

.rs-why-choose-2__thumb {
    margin-left: 45px;
    display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-why-choose-2__thumb {
        margin-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose-2__thumb {
        margin-left: 0;
    }
}

@media only screen and (max-width: 575px) {
    .rs-why-choose-2__thumb {
        display: block;
    }
}

.rs-why-choose-2__thumb .rs-thumb-1 {
    margin-right: 20px;
    min-width: 310px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rs-why-choose-2__thumb .rs-thumb-1 {
        min-width: 210px;
    }
}

@media only screen and (max-width: 767px) {
    .rs-why-choose-2__thumb .rs-thumb-1 {
        min-width: 285px;
    }
}

@media only screen and (max-width: 575px) {
    .rs-why-choose-2__thumb .rs-thumb-1 {
        margin-bottom: 30px;
        width: inherit;
        margin-right: 0;
    }
}

.rs-why-choose-2__thumb .rs-thumb-1 img {
    border-radius: 10px;
    width: 100%;
}

.rs-why-choose-2__thumb .rs-thumb-2 img {
    margin-bottom: 15px;
    border-radius: 10px;
    width: 100%;
}

.rs-why-choose-2.rs-service-why-choose .rs-section-title .top-sub-heading span {
    background-color: transparent;
    background-image: linear-gradient(99.43deg, #513DE8 0.99%, #8366E3 99.43%);
}

.rs-why-choose-2.rs-service-why-choose .rs-progress__bar {
    background-color: #EEECFD;
}

.rs-why-choose-2.rs-service-why-choose .rs-progress__inner {
    background-color: #F26F20;
}

.rs-why-choose-2.rs-service-why-choose .rs-why-choose-2__thumb .rs-counter-content {
    text-align: center;
}

.rs-why-choose-2.rs-service-why-choose .rs-why-choose-2__thumb .rs-counter-content .title {
    font-size: 30px;
    color: #F26F20;
    font-family: "Urbanist", sans-serif;
}

.rs-why-choose-2.rs-service-why-choose .rs-why-choose-2__thumb .rs-counter-content .title span {
    font-size: 64px;
}

.rs-why-choose-2.rs-service-why-choose .rs-why-choose-2__thumb .rs-counter-content>span {
    font-family: "Urbanist", sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #0a0a0a;
}

.rs-progress-skill {
    position: relative;
    margin-top: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rs-progress-skill {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .rs-progress-skill {
        margin-bottom: 50px;
    }
}

.rs-progress__title {
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 12px;
    font-family: "Urbanist", sans-serif;
    color: #002559;
}

.rs-progress__bar {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 30px;
    display: block;
    background-color: #FEF5EE;
}

.rs-progress__inner {
    position: relative;
    display: block;
    width: 0px;
    height: 6px;
    border-radius: 30px;
    background: linear-gradient(130deg, #f24c1a 0%, #f28e26 100%);
    transition: all 1500ms ease;
    margin-bottom: 50px;
    top: 2px;
}

.rs-progress__number {
    position: absolute;
    right: 0px;
    bottom: 10px;
    text-align: center;
    opacity: 0;
    margin-bottom: 0;
    line-height: 1;
    transition: all 500ms ease;
    font-size: 14px;
    color: #002559;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 45px;
    text-align: center;
    display: inline-block;
    padding: 8px 0 12px;
}

.rs-progress-skill .counted .count-text {
    opacity: 1;
}

.howitbg {
    background-color: #021438;
}

.howitnri {
    color: #fff;
}

.howittitle {
    color: #fff;
}

.howitsubtitle {
    color: #fff;
}

.servicesection-title {
    color: #003B5C;
    font-size: 50px;
    font-weight: 700;
}

.servicedescription {
    color: #343a40;
    font-size: 18px;
}

.service-card {
    position: relative;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}


.service-icon-wrapper {
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.service-icon {
    width: 71%;
    object-fit: cover;
    border-radius: 50%;
}

.service-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.service-description {
    font-size: 1rem;
    color: #777;
    margin-top: 10px;
}

/*  */
.style-two.service-area {
    background: url(../img/service-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 15px 0 100px;
}

.style-two.service-area .section-title h1 {
    color: #fff;
}

.style-two .single-services-box {
    padding: 37px 0 30px 40px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
    border-radius: 5px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.style-two .single-services-box:before {
    position: absolute;
    content: "";
    left: 50%;
    top: 0;
    width: 0;
    height: 100%;
    transition: 0.5s;
    z-index: -1;
    border-radius: 5px;
}

.style-two .single-services-box:hover:before {
    left: 0;
    width: 100%;
    background-color: 'none' !important;
}

.service-icon-thumb {
    position: relative;
}

.service-icon-thumb:before {
    position: absolute;
    content: "";
    left: -8px;
    top: -7px;
    width: 40px;
    height: 40px;
    background: #E6EEFB;
    transition: 0.5s;
    z-index: -1;
    border-radius: 100%;
}

.style-two .single-services-box:hover .service-icon-thumb:before {}

.service-icon-thumb img {
    transition: 0.5s;
}

.style-two .single-services-box:hover .service-icon-thumb img {
    filter: brightness(0) invert(1);
}

.style-two .service-number h1 {
    background: #E6EEFB;
    position: absolute;
    right: 0;
    top: 33px;
    transition: 0.5s;
    font-size: 26px;
    line-height: 50px;
    color: #8abf4f;
    font-weight: 600;
    font-family: "Rajdhani";
    padding: 0 14px 0 28px;
    border-radius: 30px 0 0 30px;
    margin: 0;
}

.style-two .service-content h4 {
    font-size: 26px;
    line-height: 50px;
    color: #041424;
    font-weight: 700;
    margin: 16px 0 12px 0;
    transition: 0.5s;
}

.style-two .single-services-box:hover .service-content h4,
.style-two .single-services-box:hover .service-content p {
    color: #000;
}

.style-two .service-content p {
    margin: 0 0 24px;
    transition: 0.5s;
    color: #686868;
}

.style-two .single-services-box:hover .service-content p {
    color: #000;
}

.style-two .service-content a {
    display: inline-block;
    transition: 0.5s;
    font-size: 19px;
    letter-spacing: -10px;
    color: #fff;
    font-weight: 600;
    font-family: "Rajdhani";
}

.style-two .single-services-box:hover .service-content a {
    letter-spacing: 0;
}

.style-two .service-content i {
    display: inline-block;
    color: #0B59DB;
    font-size: 22px;
    transition: 0.5s;
}

.style-two .single-services-box:hover .service-content i {
    left: 7px;
    position: relative;
}

/* .style-two .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.style-two .col-lg-4, .style-two .col-md-6 {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.style-two .single-services-box {
    width: 100%;
}

.style-two .service-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
} */




.serviceheading {
    color: #001328 !important;
    font-weight: 700;
    font-size: 28px;
}

.servicesubheadinng {
    color: #001328 !important;
    font-size: 16px;
    text-align: justify;
}

.carousel {
    position: relative;
    overflow: hidden;
}

.carousel-item img {
    height: 100vh;
    object-fit: cover;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.3);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 2;
}

.main-headding .span {
    font-size: 20px;
}

.buttons .theme-btn1 {
    text-align: center;
}

.style-one.testimonial-area {
    background: url(../img/test.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 50px 0 0;
    position: relative;
}

.style-one.testimonial-area .section-title.center {
    margin-bottom: 14px;
}

.style-one .testi-desc p {
    font-size: 20px;
    line-height: 34px;
    color: #041424;
    width: 65%;
    margin: auto;
    margin-top: 28px;
}

.style-one .testi-rating {
    margin-top: 20px;
}

.style-one .testi-rating ul li {
    display: inline-block;
}

.style-one .testi-rating ul li i {
    color: #FF9100;
    margin: 0 3px;
}

.style-one .testi-author h4 {
    font-size: 24px;
    margin: 53px 0 5px;
    font-weight: 600;
    position: relative;
}

.style-one .testi-author h4:before {
    position: absolute;
    content: "";
    width: 28px;
    height: 2px;
    left: 0;
    right: 0;
    top: -25px;
    background: #0B59D7;
    margin: auto;
}

.style-one.testimonial-area .owl-dots {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    text-align: center;
}

.style-one.testimonial-area .owl-dot {
    background: url(../img/testi-1.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
    width: 100px;
    height: 100px;
    margin: 0 22px;
    display: inline-block;
}

.style-one.testimonial-area .owl-dot.active {
    transform: scale(1.1);
    border: 6px solid #0c5adb;
    border-radius: 50%;
}

.style-one.testimonial-area .owl-prev,
.style-one.testimonial-area .owl-next {
    display: inline-block;
    position: absolute;
    top: 31%;
    font-size: 20px;
    color: #0c5adb;
}

.style-one.testimonial-area .owl-prev {
    left: 14px;
}

.style-one.testimonial-area .owl-next {
    right: 9px;
}

.style-one.testimonial-area .owl-prev i,
.style-one.testimonial-area .owl-next i {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    filter: drop-shadow(0px 10px 10px rgba(207, 218, 235, 0.2));
    background-color: #ffffff;
    line-height: 65px;
    transition: 0.5s;
    text-align: center;
}

.style-one.testimonial-area .owl-prev i:hover,
.style-one.testimonial-area .owl-next i:hover {
    background: #0c5adb;
    color: #fff;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: #1a2a5c !important;
}


@media (max-width: 768px) {

    .style-one.testimonial-area {
        padding: 80px 0 80px;
    }

    .style-one .testi-desc p {
        font-size: 16px;
        line-height: 28px;
        width: 80%;
        margin-top: 120px;
    }

    .style-one .testi-author h4 {
        font-size: 14px;
        margin: 25px 0 5px;
    }

    .style-one .testi-rating ul li i {
        margin: 0 2px;
    }

    .style-one.testimonial-area .owl-dot {
        width: 80px;
        height: 80px;
        margin: 0 10px;
    }

    .style-one.testimonial-area .owl-prev i,
    .style-one.testimonial-area .owl-next i {
        width: 50px;
        height: 50px;
        font-size: 18px;
        line-height: 50px;
    }

    .style-one.testimonial-area .owl-dots {
        top: 15px;
    }
}

@media (max-width: 576px) {

    .style-one.testimonial-area {
        padding: 60px 0 60px;
    }

    .style-one .testi-desc p {
        font-size: 14px;
        line-height: 24px;
        width: 90%;
        margin-top: 80px;
    }

    .style-one .testi-author h4 {
        font-size: 18px;
        margin: 30px 0 5px;
    }

    .style-one .testi-rating ul li i {
        margin: 0 1px;
    }

    .style-one.testimonial-area .owl-dot {
        width: 60px;
        height: 60px;
        margin: 0 8px;
    }

    .style-one.testimonial-area .owl-prev i,
    .style-one.testimonial-area .owl-next i {
        width: 40px;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
    }

    .style-one.testimonial-area .owl-dots {
        top: 10px;
    }
}

.style-one .testi-profile-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px auto;
    display: block;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    border: 5px solid #0B59D7;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.style-one .testi-profile-img:hover {
    transform: scale(1.1);
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.3);
    border-color: #FF9100;
}

@media (max-width: 768px) {
    .style-one .testi-profile-img {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 576px) {
    .style-one .testi-profile-img {
        width: 80px;
        height: 80px;
    }
}
